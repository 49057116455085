import 'bootstrap/dist/css/bootstrap.min.css';
import Router from './Routers/Router';

function App() {
  
  return (
    <div className="App">
 
     <Router></Router>

    </div>
  );
}

export default App;
